<template>
  <div class="container">
    <div class="handle-box">
      <el-button icon="el-icon-download" type="primary" @click="exportToExcel"
        >导出</el-button
      >
      <div class="searchCon">
        <div class="searchItem">
          <el-select
            clearable
            v-model="searchQuery.invoiceTaxNum"
            placeholder="按主体查询"
          >
            <el-option
              v-for="item in enterprises"
              :key="item.id"
              :label="item.departName"
              :value="item.ein"
            >
            </el-option>
          </el-select>
        </div>

        <div class="searchItem">
          <span>日期选择</span>

          <el-date-picker
            v-model="daterange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="getRangDate"
          >
          </el-date-picker>
        </div>
      </div>
      <el-button icon="el-icon-search" type="primary" @click="fetchData"
        >查询</el-button
      >
      <el-button icon="el-icon-refresh" @click="refreshObj(searchQuery)"
        >重置</el-button
      >
    </div>
    <!--数据表格-->
    <el-table
      :data="list"
      border
      fit
      highlight-current-row
      @row-dblclick="onEdit"
    >
      <el-table-column label="订单号" prop="sqtOrderId" />
      <el-table-column label="账期" prop="billPeriod" />
      <el-table-column label="申请人" prop="applicantName" />
      <el-table-column label="所属部门" prop="applicantDepartmentName" />
      <el-table-column label="金额(元)" prop="transactionAmount">
        <template slot-scope="scope">
          <span>
            {{
              scope.row.transactionType == 2 || scope.row.transactionType == 17
                ? `-${scope.row.transactionAmount}`
                : scope.row.transactionAmount
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="支付类型" prop="payType">
        <template slot-scope="scope">
          <span :class="scope.row.payType == 10 ? '' : 'font-red'">
            {{ scope.row.payType | formatTypr("payType") }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="交易类型" prop="payType">
        <template slot-scope="scope">
          <span>
            {{
              scope.row.transactionType | formatTypr("transactionType")
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="消费时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.traceTime) }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="280" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="small"
            @click="getOrderDetail(scope.row.sqtBizOrderId)"
          >
            消费详情
          </el-button>
          <el-button
            type="success"
            icon="el-icon-download"
            size="small"
            @click="showDowvoucher(scope.row)"
          >
            下载凭证
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageInfo.currentPage"
      :page-sizes="[10, 20, 30, 50, 100]"
      :page-size="pageInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total"
      @size-change="onSizeChange"
      @current-change="onPageChange"
    />
    <!-- 编辑信息 -->
    <el-dialog
      title="消费详情"
      width="50%"
      :visible.sync="detailShow"
      :close-on-click-modal="false"
    >
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            收货人
          </template>
          {{ detailObj.recipientName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            手机号
          </template>
          {{ detailObj.recipientPhone }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i>
            订单总额
          </template>
          {{ detailObj.total }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            收货地址
          </template>
          {{ detailObj.recipientAddress }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            菜品明细
          </template>

          <span
            v-for="(item, index) in detailObj.foodList"
            :key="index"
            class="m-r-10"
            >{{ item.name }} <em :style="{ 'margin-left': '5px' }"></em>*{{
              item.count
            }}份</span
          >
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <el-dialog
      title="下载凭证"
      :visible.sync="dowvoucherVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="dowvoucherVisible = false"
    >
      <div
        style="margin: 0 auto; padding: 0 20px; background: #fff"
        id="poster"
        class="echarts renderjs"
      >
        <div id="my-node">
          <div style="background: #fff" v-html="htmlContent"></div>
          <!-- <rich-text :nodes="htmlContent"></rich-text> -->
        </div>
      </div>
      <span slot="footer">
        <!-- <el-button @click="dowvoucherVisible = false">Cancel</el-button> -->
        <el-button type="primary" @click="shotPic">下载</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/common";
import { export_json_to_excel } from "@/assets/js/Export2Excel";
import domtoimage from "dom-to-image";
export default {
  name: "meituanDepartment",

  data() {
    var date = new Date();
    var startDate = this.$publicMethods.formatDate(
      new Date(date.getFullYear(), date.getMonth(), 1),
      4
    );
   /* var endDate = this.$publicMethods.formatDate(
      new Date(date.getFullYear(), date.getMonth() + 1, 0 ),
      4
    );*/
    // 获取当前日期
    const now = new Date();
    // 获取昨天的日期
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const year = yesterday.getFullYear();
    const month = ('0' + (yesterday.getMonth() + 1)).slice(-2); // 月份从0开始，所以加1
    const day = ('0' + yesterday.getDate()).slice(-2);
    var endDate = `${year}-${month}-${day}`;


    //var endDate = "2024-08-21";
    return {
      detailObj: {},
      owvoucherData: {},
      searchQuery: {
        invoiceTaxNum: "",
        startDate: startDate,
        endDate: endDate,
      },
      daterange: [startDate, endDate],
      form: { departId: "" },
      departmentList: [],
      departmentTreeList: [],
      userList: [],

      mealTimeArr: [
        {
          value: "",
        },
      ],
      handleType: "add",
      pageInfo: {
        pageNo: 1,
        pageSize: 100,
        total: 0,
        apiName: "/meituan/bill/queryList",
      },
      list: [],
      listLoading: true,
      dowvoucherVisible: false,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: [],
      enterprises: [],
      detailShow: false,
      htmlContent: "",
    };
  },
  created() {
    this.fetchData();
    this.getTitList();
    // this.generateBillingHtml();
  },
  filters: {
    formatTypr: function (value, type) {
      const map = {
        payType: { 10: "企业支付", 20: "个人支付" },
        transactionType: {
          1: "支付",
          2: "退款",
          16: "服务费",
          17: "服务费-退款",
        },
      };

      return map[type][value];
    },
  },

  methods: {


    async getAllData() {
      let pageNum = parseInt(this.pageInfo.total / this.pageInfo.pageSize) + 1;
      let arr = [];
      for (let index = 1; index < pageNum + 1; index++) {
        let obj = Object.assign(
          {},
          {
            apiName: "/meituan/bill/queryList",
            pageNo: index,
            pageSize: this.pageInfo.pageSize,
          },
          this.searchQuery
        );
        let res = await api.commonPost(obj);

        if (res.code == 200) {
          for (let i = 0; i < this.pageInfo.pageSize; i++) {
            if (res.data.result[i]) {
              arr.push(res.data.result[i]);
            }
          }
        }
      }
      return arr;
    },

    // 获取凭证HTML模板
    async showDowvoucher(row) {
      console.log("rowrowrow", row.budgetKey);
      let id = row.budgetKey;
      let res = await api.commonGet({
        apiName: `/meituanApplication/generateBillingHtml?id=${id}`,
      });
      console.log("resssssss.", res);

      if (res.code == 200) {
        this.htmlContent = res.data;
        this.dowvoucherVisible = true;
      }

      // this.dowvoucherData = row;
    },
    //获取主体信息
    async getTitList() {
      let res = await api.commonGet({
        apiName: "/meituanInvoice",
        pageSize: 50,
      });
      if (res.code == 200) {
        this.enterprises = res.data.data;
      }
    },
    async getOrderDetail(sqtBizOrderId) {
      let res = await api.commonGet({
        apiName: "/meituan/bill/queryOrderDetail",
        sqtBizOrderId: sqtBizOrderId,
      });
      this.detailObj = res.data.wmOrder;
      this.detailShow = true;
    },
    // 获取凭证HTML代码
    async generateBillingHtml() {
      let res = await api.commonGet({
        apiName:
          "/meituanApplication/generateBillingHtml?id=1540267938793320450",
      });
      if (res.code == 200) {
        this.htmlContent = res.data;
      }
    },
    formatDate(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
    billDetail() {
      console.log("done");
    },
    getDepartId(e) {
      this.form.departId = e[e.length - 1];
    },
    async getTreeList() {
      let res = await api.commonPost({
        apiName: "/department/getTreeList",
      });
      this.departmentTreeList = res.data;
      console.log("res", res);
    },
    onRefresh() {
      this.fetchData();
    },
    getRangDate() {
      this.searchQuery.startDate = this.daterange[0];
      this.searchQuery.endDate = this.daterange[1];
    },
    onPageChange(page) {
      this.pageInfo.pageNo = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    //excel数据导出
    exportToExcel() {
      require.ensure([], async () => {
        const tHeader = [
          "订单号",
          "账期",
          "申请人",
          "消费金额",
          "消费类型",
          "消费时间",
        ];
        const filterVal = [
          "sqtOrderId",
          "billPeriod",
          "applicantName",
          "transactionAmount",
          "payType",
          "traceTime",
        ];
        let allList = await this.getAllData();

        const list = this.list;
        const data = this.formatJson(filterVal, allList);
        export_json_to_excel(tHeader, data, "美团账单");
      });
    },
    formatJson(filterVal, jsonData) {
      for (var i = 0; i < jsonData.length; i++) {
        jsonData[i].traceTime = this.formatDate(jsonData[i].traceTime);
        jsonData[i].payType == 10
          ? (jsonData[i].payType = "企业支付")
          : (jsonData[i].payType = "个人支付");
      }

      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    onDel(id) {
      this.$confirm("您确定要删除此条记录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        ids.push(id);
        api
          .commonPost({
            apiName: "/meituanDepartmentApprover/remove",
            ids: ids,
          })
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error("删除错误！");
          });
      });
    },

    removeDomain(item) {
      var index = this.mealTimeArr.indexOf(item);
      if (index !== -1) {
        this.mealTimeArr.splice(index, 1);
      }
    },

    addTimeRange() {
      this.mealTimeArr.push({
        value: "",
        key: Date.now(),
      });
    },
    async getUserList(e) {
      let res = await api.commonPost({
        apiName: "/user/userDropDownList?departmentId=" + e,
      });
      this.userList = res.data;
    },

    async handleSave(type) {
      var obj = {};

      if (type == "add") {
        obj = Object.assign({}, this.form, {
          apiName: "/meituanMail/create",
        });
      } else {
        obj = Object.assign({}, this.form, {
          apiName: "/meituanMail/update",
        });
      }
      let res = await api.commonPost(obj);
      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.selectDlgShow = false;
        this.dlgLoading = false;
        this.fetchData();
      }
    },

    //添加
    onAdd() {
      this.handleType = "add";
      this.form = {
        recipientMail: "",
        departId: [],
        recipient: "",
      };
      this.selectDlgShow = true;
    },
    //编辑
    onEdit(row) {
      this.handleType = "edit";
      this.form = row;
      this.selectDlgShow = true;
    },
    //查询列表
    async fetchData() {
      let obj = Object.assign({}, this.pageInfo, this.searchQuery);
      let res = await api.commonPost(obj);
      if (res.code == 200) {
        this.list = res.data.result;
        this.pageInfo.total = parseInt(res.data.totalCount);
      }
    },

    //获取分局部门列表，用于添加

    async getDepartList() {
      let res = await api.commonPost({ apiName: "/department/getBranchList" });
      this.departmentList = res.data;
    },

    getRangTimeStr(arr) {
      let strArr = [];
      arr.forEach((element) => {
        strArr.push(element.value[0] + "~" + element.value[1]);
      });
      let permission = strArr.join(",");
      return permission;
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    shotPic() {
      const node = document.getElementById("my-node"); // 通过id获取dom
      console.log(node, "node");
      domtoimage
        .toJpeg(node)
        .then((dataUrl) => {
          //输出图片的Base64,dataUrl
          // 下载到PC
          const a = document.createElement("a"); // 生成一个a元素
          const event = new MouseEvent("click"); // 创建一个单击事件
          a.download = "用餐凭证"; // 设置图片名称没有设置则为默认
          a.href = dataUrl; // 将生成的URL设置为a.href属性
          a.dispatchEvent(event); // 触发a的单击事件
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },
  },
};
</script>
<style scoped>
.searchCon {
  display: inline-block;
}
.searchItem:nth-child(1) {
  margin-left: 20px;
}
.searchItem {
  display: inline-block;
  margin-right: 20px;
}
.searchItem span {
  padding: 0 20px;
  background: #f5f7fa;
  color: #909399;
  line-height: 40px;
  height: 40px;
  display: none;
  border: 1px solid #dcdfe6;
  border-right: none;
  border-radius: 4px;
}
.font-red {
  color: #f00;
}
</style>
